/* eslint-disable */
<template>
  <div id="shop">
    <Header :newCart="newCart"></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Shop</h2>
                <ul class="breadcrumb-menu list-style">
                  <li>
                    <!--<a href="index-2.html">Home-->
                    <router-link to="/" style="color: white">Home </router-link>
                  </li>
                  <li>Shop</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="Shop-wrap ptb-100">
        <div class="container">
          <div class="row">
            <div
              class="
                col-xl-4 col-lg-12
                order-xl-1 order-lg-2 order-md-2 order-2
              "
            >
              <div class="sidebar">
                <!-- <div class="sidebar-widget search-box">
                  <h4>Search</h4>
                  <div class="form-group">
                    <input type="search" placeholder="Search" />
                    <button type="submit">
                      <i class="flaticon-search"></i>
                    </button>
                  </div>
                </div> -->
                <div class="sidebar-widget categories">
                  <h4>Category</h4>
                  <div class="category-box">
                    <ul class="list-style">
                      <li @click="categoryFilter('')">
                        <router-link to="#/"
                          >All Dishes<i class="flaticon-right-arrow-angle"></i
                        ></router-link>
                      </li>
                      <li
                        v-for="(item, value, index) in Items"
                        :key="index"
                        @click="categoryFilter(value)"
                      >
                        <!--<a href="shop-left-sidebar.html"
                          >Dishes<i class="flaticon-right-arrow-angle"></i>
                        </a>-->
                        <router-link to=""
                          >{{ value }}<i class="flaticon-right-arrow-angle"></i
                        ></router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                col-xl-8 col-lg-12
                order-xl-2 order-lg-1 order-md-1 order-1
              "
              id="mainitems"
            >
              <!-- <div class="row align-items-center mb-25">
                <div class="col-xl-6 col-lg-5 col-md-4">
                  <div class="profuct-result">
                    <p>Showing 10 of 120 Products</p>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="filter-item-cat">
                    <select>
                      <option value="1">Sort By Most Popular</option>
                      <option value="2">Sort By High To Low</option>
                      <option value="3">Sort By Low To High</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4">
                  <div class="filter-item-num">
                    <select>
                      <option value="1">Show 10</option>
                      <option value="2">Show 20</option>
                      <option value="3">Show 30</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div class="row justify-content-center">
                <!-- item list -->
                <item-list
                  :items="Items"
                  v-on:addItem="addItem($event)"
                  :category="category"
                  :resInfo="resInfo"
                />
                <!-- item list -->
              </div>
              <!-- Pagination Section -->
              <!-- <div class="page-navigation">
                <div class="row align-items-center">
                  <div
                    class="col-xl-3 col-lg-3 col-md-3 col-sm-3 text-sm-start text-center smb-20"
                  >
                    <router-link to="/shop" class="btn prev">Prev Page</router-link>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center smb-20">
                    <ul class="page-nav list-style">
                      <li><a class="active" href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                    </ul>
                  </div>
                  <div
                    class="col-xl-3 col-lg-3 col-md-3 col-sm-3 text-sm-end text-center"
                  >
                    <router-link to="/shop" class="btn next">Next Page</router-link>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
/* eslint-disable */
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getMenu, getRestaurantInfo } from "@/store/api";
import ItemList from "./ItemList.vue";
export default {
  name: "shop",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      thumb1: require("@/assets/img/blog/post-thumb-1.jpg"),
      thumb3: require("@/assets/img/blog/post-thumb-3.jpg"),
      thumb2: require("@/assets/img/blog/post-thumb-2.jpg"),
      Items: [],
      newCart: [],
      cartshow: false,
      category: "",
      pageOfItems: [],
      resInfo: null,
    };
  },
  components: {
    Header,
    Footer,
    ItemList,
  },
  mounted() {
    this.getResInfo();
    // eslint-disable-next-line no-unused-expressions
    getMenu().then((res) => {
      // console.log(res.data['items']['Fast food'])
      let arr = [];
      arr = res.data.items;

      Object.keys(arr).map((e) => {
        console.log(res.data.items[e][0].price);
      });

      // Object.keys(res.data.items).map((item) => (
      //     console.log(item)
      // ));
      console.log("menu", res.data.items);
      this.Items = res.data.items;
      console.log("");
      // eslint-disable-next-line no-sequences
    });
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getResInfo() {
      getRestaurantInfo().then((res) => {
        this.resInfo = res.data;
        console.log("restaurant info: " + this.resInfo);
      });
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    categoryFilter(category) {
      this.category = category;
      document.getElementById("mainitems").scrollIntoView({
        behavior: "smooth",
      });
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      this.scrollClass = true;
    },
    addItem(event) {
      this.newCart = event;
    },
  },
};
</script>
<style scoped>
.order-2 {
  order: 1 !important;
}
.sidebar-widget.categories {
  margin-top: 10px !important;
  margin-bottom: 18px !important;
}
@media only screen and (max-width: 767px) {
  .ptb-100 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .badge.remave {
    font-size: 14px !important;
    position: relative;
    top: -32px;
    left: 57px;
  }
}
</style>
