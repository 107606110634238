<template>
  <div>
    <div
      :id="`${value}`"
      v-for="(item, value, index) in items"
      :key="index"
      class="row"
    >
      <div
        class="col-lg-6 col-md-6"
        v-for="(item1, index1) in items[value]"
        :key="index1"
      >
        <div
          class="feature-card style1"
          v-if="item1.category_name == category || category == ''"
        >
          <div class="feature-img">
            <img
              :src="baseUrl() + item1.image"
              alt="Image"
              onError="this.src='./assets/img/New-Project.jpg';"
            />
          </div>
          <div class="feature-info">
            <h3 class="feature-title">
              <!--<a href="#">{{ item1.name }}</a>-->
              <router-link :to="`/shopdetails/${item1.id}`">{{
                item1.name
              }}</router-link>
            </h3>
            <div class="feature-meta">
              <p class="feature-price">
                $ {{ item1.price
                }}<span v-if="item1.old_price > 0"
                  >$ {{ item1.old_price }}</span
                >
              </p>
            </div>
            <!--<a class="btn style2" href="cart.html"
                        ><i class="flaticon-shopping-bag-1"></i>Add To Cart</a
                      >-->
            <router-link
              :to="`/shopdetails/${item1.id}`"
              class="btn style2"
              v-if="item1.addon_categories.length > 0"
            >
              <span>Add options</span>
            </router-link>
            <button
              :class="showButton == true ? 'btn style2' : 'btn style2 disabled'"
              @click="addtoCart(item1)"
              v-else
            >
              <i class="flaticon-shopping-bag-1"></i>Add To Cart
            </button>
            <span class="badge badge-danger" v-if="showButton == false"
              >Restaurant Closed</span
            >
            <!-- <div class="discount-tag">
              <img :src="circle1" alt="Image" />
              <p><span>Save</span> 30%</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addCart, getCart, baseUrl, getLocalStorage } from "@/store/service";
export default {
  props: ["items", "category", "resInfo"],
  data() {
    return {
      circle1: require("@/assets/img/feature/circle-1.png"),
      selectItem: "",
      options: [],
      cart: [""],
      close: "",
      single: [],
      multi: [],
      addons: [],
      selectedaddons: [],
      addOnTotal: 0,
      singleAddOnTotal: 0,
      multiAddOnTotal: 0,
      showButton: null,
      user: [],
    };
  },
  mounted() {
    this.user = getLocalStorage("userData");
  },
  watch: {
    resInfo() {
      console.log(this.resInfo);
      console.log(`online--------${this.resInfo.open}`);
      console.log(`table--------${this.resInfo.table_order_open}`);
      if (!this.user) {
        if (this.resInfo.open === 1) {
          this.showButton = true;
        } else {
          this.showButton = false;
        }
        console.log("allusers-->" + this.showButton);
      } else {
        if (this.user && !this.user.role) {
          if (this.resInfo.open === 1) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
          console.log("user-->" + this.showButton);
        } else {
          if (this.resInfo.table_order_open === 1) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
          console.log("table-->" + this.showButton);
        }
      }
    },
  },
  methods: {
    baseUrl() {
      return baseUrl();
    },
    addtoCart(item) {
      this.cart = getCart("cart");

      this.selectItem = item;
      if (item.addon_categories.length > 0) {
        this.options = [];
        this.addons = [];
        item.addon_categories.map((data) => {
          if (data.type === "SINGLE") {
            this.options.push(data);
          } else {
            this.addons.push(data);
          }
        });
      } else {
        if (this.cart !== null && this.cart.length > 0) {
          for (var j = 0; j < this.cart.length; j++) {
            if (this.cart[j].id === item.id) {
              this.cart[j].quantity++;
              break;
            } else if (
              item.id !== this.cart[j].id &&
              j === this.cart.length - 1
            ) {
              item.quantity = 1;
              this.cart.push(item);
              break;
            }
          }
        } else {
          item.quantity = 1;
          this.cart = [item];
        }
        this.$toast.success("An item added to cart.", {
          timeout: 1000,
        });
        this.$emit("addItem", this.cart);
        addCart("cart", JSON.stringify(this.cart));
      }
    },
    cardModalClose(item) {
      item.selectedaddons = this.single.concat(this.multi);
      item.price = 0;
      item.quantity = 1;
      item.addOnTotal = this.addOnTotal;
      if (this.cart != null) {
        this.cart = getCart("cart");
        this.cart.push(item);
      } else {
        this.cart = [item];
      }
      console.log(this.cart);
      // this.cart = item
      this.$toast.success("An item added to cart.", {
        timeout: 1000,
      });
      this.$emit("addItem", this.cart);
      addCart("cart", JSON.stringify(this.cart));
      this.close = "close";
    },
    selectAddon(event, category) {
      // console.log(event)
      var addon = JSON.parse(event.target.value);
      var select = {
        addon_category_name: category.name,
        addon_id: addon.id,
        addon_name: addon.name,
        price: addon.price,
      };
      console.log(select);
      if (category.type === "SINGLE") {
        this.single = [select];
        this.singleAddOnTotal = parseFloat(this.single[0].price);
        this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
      } else if (category.type === "MULTI") {
        if (this.multi.length > 0) {
          for (var j = 0; j < this.multi.length; j++) {
            if (select.addon_id === this.multi[j].addon_id) {
              this.multiAddOnTotal -= parseFloat(this.multi[j].price);
              // console.log(this.addOnTotal + 'if')
              this.multi.splice(j, 1);
              this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
              break;
            } else if (
              select.addon_id !== this.multi[j].addon_id &&
              j === this.multi.length - 1
            ) {
              this.multi.push(select);
              this.multiAddOnTotal += parseFloat(select.price);
              // console.log(this.addOnTotal + 'else-if')
              this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
              break;
            }
          }
        } else {
          this.multi = [select];
          this.multiAddOnTotal += parseFloat(this.multi[0].price);
          this.addOnTotal = this.singleAddOnTotal + this.multiAddOnTotal;
          // console.log(this.addOnTotal + 'else')
        }
      }
    },
  },
};
</script>
<style>
.badge {
  background: red;
  color: white;
  float: right;
  display: inline-block;
  margin-top: 16px;
  margin-right: -1pc;
  border-radius: 20px;
}
</style>
